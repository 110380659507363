import { graphql, Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import * as styles from '../Blog/styles/blog.module.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import Layout from '../components/Layout';

export default function blogPost({ data }) {
  const Blog = data.allBlogDummyJson.nodes;
  console.log(Blog);

  const [off, setOff] = useState(0);
  const handleScroll = () => setOff(window.pageYOffset);

  const tl = gsap.timeline();
  gsap.registerPlugin(ScrollTrigger);

  let blogBanner = useRef(null);
  let blogBannerHeader = useRef(null);
  let blogBannerText = useRef(null);
  let blogSection = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // useEffect(() => {
  //   tl.from(blogBannerHeader, 1, {
  //     y: -50,
  //     ease: 'power4.out',
  //     delay: 0.2,
  //     opacity: 0,
  //   })

  //     .from(blogBannerText, 0.6, {
  //       y: 50,
  //       ease: 'power4.out',
  //       delay: 0.1,
  //       opacity: 0,
  //     })
  //     .from(blogSection, 1, {
  //       y: 100,
  //       opacity: 0,
  //       ease: 'power3.easeOut',
  //       delay: 0.3,
  //       stagger: {
  //         amount: 0.4,
  //       },
  //       scrollTrigger: {
  //         trigger: blogBanner,
  //         start: '50%',
  //         end: '100%',
  //         pinSpacing: false,
  //         scrub: true,
  //       },
  //     });
  // }, []);

  return (
    <Layout>
      <div className={styles.blog_banner_wrapper}>
        <div>
          <div
            className={styles.blog_banner}
            ref={(el) => {
              blogBanner = el;
            }}>
            <h1 className={styles.blog_banner_bg_txt} data-text='NEWS'>
              NEWS
            </h1>
            <div
              className={styles.blog_banner_content}
              style={{ transform: `translateY(${off * -0.9}px)` }}>
              <h3
                className={styles.blog_banner_content_header}
                ref={(el) => {
                  blogBannerHeader = el;
                }}>
                Latest Updates
              </h3>
              <p
                className={styles.blog_banner_content_text}
                ref={(el) => {
                  blogBannerText = el;
                }}>
                All the most current news and events of our creative team.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.blogcontent}>
          <div className={styles.blog_nav_link}>
            <Link to='/'>ALL</Link>
            <Link to='/Blog/blogPost/#career'>CAREER {'&'} GROWTH</Link>
            <Link to='/Blog/blogPost/#conversations'>CONVERSATIONS</Link>
            <Link to='/Blog/blogPost/#process'>PROCESS</Link>
            <Link to='/'>PRODUCT</Link>
            <Link to='/'>TEACHERS {'&'} STUDENT</Link>
            <Link to='/'>UI DESIGN</Link>
            <Link to='/'>UX DESIGN</Link>
          </div>
          <div
            ref={(el) => {
              blogSection = el;
            }}
            className={styles.blogContent_inner}>
            <div id='career'>
              <div className={styles.blog_wrapper}>
                <div className={styles.blog_inner}>
                  <h3 className={styles.blog_subheader}>Career {'&'} Growth</h3>
                  <Link
                    to={'/Blog/blogPost/' + Blog.slug}
                    className={styles.seeAll}>
                    SEE ALL
                  </Link>
                </div>
                <div>
                  <div className={styles.blog_inner_wrapper}>
                    {Blog.map((blog) => (
                      <>
                        <div key={blog.id} className={styles.blog_content}>
                          <Link to={'/Blog/blogPost/' + blog.slug}>
                            <img src={blog.img} alt='' />
                            <p className={styles.blog_subtopic}>
                              {blog.subTopic}
                            </p>
                            <p className={styles.blog_topic}>{blog.topic}</p>
                            <p className={styles.blog_text}>{blog.content}</p>
                            <div className={styles.authordetails}>
                              <img src={blog.authorimg} alt='' />
                              <p className={styles.author}>{blog.author}</p>
                            </div>
                            <p className={styles.blog_date}>{blog.Date}</p>
                          </Link>
                        </div>
                      </>
                    ))}
                  </div>
                  <p className={styles.viewmore}>SEE ALL</p>
                </div>
              </div>
            </div>

            <div id='conversations'>
              <div className={styles.blog_second_wrapper}>
                <div className={styles.blog_second_inner}>
                  <h3 className={styles.blog_subheader}>Conversations</h3>
                  <p className={styles.seeAll}>SEE ALL</p>
                </div>
                <div>
                  <div className={styles.blog_inner_wrapper}>
                    {Blog.map((blog) => (
                      <>
                        <div key={blog.id} className={styles.blog_content}>
                          <Link to={'/Blog/blogPost/' + blog.slug}>
                            <img src={blog.img} alt='' />
                            <p className={styles.blog_subtopic}>
                              {blog.subTopic}
                            </p>
                            <p className={styles.blog_topic}>{blog.topic}</p>
                            <p className={styles.blog_text}>{blog.content}</p>
                            <div className={styles.authordetails}>
                              <img src={blog.authorimg} alt='' />
                              <p className={styles.author}>{blog.author}</p>
                            </div>
                            <p className={styles.blog_date}>{blog.Date}</p>
                          </Link>
                        </div>
                      </>
                    ))}
                  </div>
                  <p className={styles.viewmore}>SEE ALL</p>
                </div>
              </div>
            </div>

            <div id='process'>
              <div className={styles.blog_third_wrapper}>
                <div className={styles.blog_third_inner}>
                  <h3 className={styles.blog_subheader}>Process</h3>
                  <p className={styles.seeAll}>SEE ALL</p>
                </div>
                <div>
                  <div className={styles.blog_inner_wrapper}>
                    {Blog.map((blogs) => (
                      <>
                        <div key={blogs.id} className={styles.blog_content}>
                          <Link to={'/Blog/blogPost/' + blogs.slug}>
                            <img src={blogs.img} alt='' />
                            <p className={styles.blog_subtopic}>
                              {blogs.subTopic}
                            </p>
                            <p className={styles.blog_topic}>{blogs.topic}</p>
                            <p className={styles.blog_text}>{blogs.content}</p>
                            <div className={styles.authordetails}>
                              <img src={blogs.authorimg} alt='' />
                              <p className={styles.author}>{blogs.author}</p>
                            </div>
                            <p className={styles.blog_date}>{blogs.Date}</p>
                          </Link>
                        </div>
                      </>
                    ))}
                  </div>
                  <p className={styles.viewmore}>SEE ALL</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query BlogPost {
    allBlogDummyJson {
      nodes {
        Date
        author
        authorimg
        content
        id
        img
        subTopic
        topic
        slug
      }
    }
  }
`;
